import $ from "jquery";
window.$ = $;
import toastr from 'toastr';
import "toastr/build/toastr.min.css";

toastr.options = {
  "closeButton": false,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "toast-top-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

const findToastrMessage = () => {
  document
  .querySelectorAll('.js-toastr')
  .forEach((toastrEl) =>{
    const {type, content} = toastrEl.dataset;

    if (type === "success") {
      toastr_sucess(content);
    } else if (type === "error") {
      toastr_error(content);
    } else if (type === "alert") {
      toast_alert(content)
    } else if (type === "notice") {
      toast_notice(content)
    }
  });
}

const SetToastrAlert = () => {
  document
  .querySelectorAll('.js-toastr-alert')
  .forEach((toastrEl) =>{
    const {content} = toastrEl.dataset;

    
    toastrEl.addEventListener("click", (e)=>{
      e.preventDefault();
      toastr.options.closeButton = true;
      toastr.error(content);
    });
  });
}

const toastr_sucess = (message) => {
  toastr.options.timeOut = 2000;
  toastr.success(message);
};

const toastr_error = (message, timeout=null) => {
  toastr.options.timeOut = timeout;
  toastr.options.closeButton = true;
  toastr.error(message, "錯誤訊息");
};

const toast_alert =  (message, timeout=null) => {
  toastr.options.timeOut = timeout;
  toastr.options.closeButton = true;
  toastr.warning(message, "警告訊息");
};

const toast_notice =  (message, timeout=null) => {
  toastr.options.timeOut = timeout;
  toastr.options.closeButton = true;
  toastr.info(message, "提示訊息");
};

export {findToastrMessage, SetToastrAlert, toastr_sucess, toastr_error, toast_alert, toast_notice}